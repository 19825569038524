<template>
    <section class="development-page">
        <section-head title="sectionhead.title-development" subtitle="sectionhead.subtitle-development"/>
        <section-options style="margin-top: 100px" :icons="options.icons" :description="options.description"/>
        <section-feature  lightsOn="on" :style="`margin-top: 120px; background: #F4F7FF; border-radius: 50% 50% 0 0`" :itemsCount="11" localeValues="development.features.item-" title="development.features.title"/>
        <section-services />
        <section-float title="floating.title-main-2" isWhiteBorderTop="yes">
            <element-float 
                 v-for="(element, index) in options.floatingBlocks" 
                :key="index"
                :title="element.title"
                :icon="element.icon"
                :listFirst="element.listFirst"
                :listSecond="element.listSecond"
                class="floating-el"
            />
        </section-float>
         <div class="blockchain-vertical-pointer">
            <svg class="blockchain-vertical-pointer-svg" width="46" height="2047" viewBox="0 0 46 2047" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23" cy="1032" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                <circle cx="23" cy="1032" r="4" fill="#4071F4"/>
                <circle cx="23" cy="1529.15" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                <circle cx="23" cy="1529.15" r="4" fill="#4071F4"/>
                <circle cx="23" cy="535" r="22.5" fill="#4071F4" fill-opacity="0.1" stroke="#4071F4"/>
                <circle cx="23" cy="535" r="4" fill="#4071F4"/>
                <rect opacity="0.5" x="22" y="573" width="2" height="421" fill="#4071F4"/>
                <rect opacity="0.5" x="22" y="1070" width="2" height="421" fill="#4071F4"/>
                <rect opacity="0.5" x="22" y="1567.31" width="2" height="458.534" fill="#4071F4"/>
            </svg>
        </div>
        <section-time-develop />
        <section-installation />
        <section-footer paragraph="sectionfooter.paragraph-default" />
    </section>
</template>

<script>
import sectionHead from '@/pages/asections/sectionHead.vue';
import sectionFooter from '@/pages/asections/sectionFooter.vue';
import optionsSection from '@/pages/asections/optionsSection.vue';
import features from '@/pages/asections/features.vue';
import services from '@/pages/development/sections/services.vue';
import floatingSection from '@/pages/asections/floatingSection.vue';
import floatingElement from '@/pages/apartials/floatingElement.vue';
import timedev from '@/pages/development/sections/timedev.vue';
import installation from '@/pages/development/sections/installation.vue';
import { getAlternatesForCurrentLanguage, getCanonicals } from '@/helpers/locale-helper'

export default {
    metaInfo() {
        return {
            title: this.$t('meta_title-wallets'),
            keywords: this.$t('meta_keywords'),
            description: this.$t('meta_wallets-description'),
            link: getAlternatesForCurrentLanguage().concat(getCanonicals())
        }
    },
    data: () => ({
         options: {
            description: {
                headtext: 'development.desc.headtext',
                maintext: 'development.desc.maintext',
                bottomtext: 'development.desc.bottomtext',
                footertext: 'development.desc.footertext'
            },

            icons: [{
                title: 'icontitle.web-wallet',
                name: 'options-web-wallet'
            },
            {
                title: 'icontitle.mobile-app',
                name: 'options-mobile-app'
            },
            {
                title: 'icontitle.telegram-bot',
                name: 'options-telegram-bot'
            }],

            floatingBlocks: [{
                title: 'floating.development.title-1',
                icon: 'development-floating-legal',
                listFirst: 'floating.development.el-1.it-1',
                listSecond: 'floating.development.el-1.it-2'
            },
            {
                title: 'floating.development.title-2',
                icon: 'development-floating-crypto',
                listFirst: 'floating.development.el-2.it-1',
                listSecond: ''
            },
            {
                title: 'floating.development.title-3',
                icon: 'development-floating-backups',
                listFirst: 'floating.development.el-3.it-1',
                listSecond: 'floating.development.el-3.it-2'
            }]
         }
    }),
    components: {
        'section-head': sectionHead,
        'section-footer': sectionFooter,
        'section-options': optionsSection,
        'section-feature': features,
        'section-services': services,
        'section-float': floatingSection,
        'element-float': floatingElement,
        'section-time-develop': timedev,
        'section-installation': installation
    }
}
</script>

<style lang="sass" scoped>
.development-page
    color: $color--dark

.floating-el 
    ::v-deep .floating-element_description-title_text
        margin: 0

.blockchain-vertical-pointer
    position: relative
    display: flex 
    align-items: flex-end 
    justify-content: center
    @media screen and (max-width: 768px), (min-device-width: 320px) and (max-device-width: 768px)
        display: none

    &-svg
        position: absolute

        &-light
            position: absolute
            top: 0
            z-index: 1
</style>