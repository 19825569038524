<template>
    <section class="timedev-section">
        <img class="timedev-section_img" :src="require(`@/assets/img/scenes/development-time-clock.svg`)" alt="">
        <h3 v-html="$t('development.time.title')" class="timedev-section_title" />
        <p class="timedev-section_subtitle">{{$t('development.time.subtitle')}}</p>
        <div class="timedev-section-scene">
            <img class="timedev-section-scene_img" :src="require(`@/assets/img/scenes/development-time-crypto.svg`)" alt="">
            <img class="timedev-section-scene_img--mobile" :src="require(`@/assets/img/scenes/development-time-crypto-mobile.svg`)" alt="">
            <div class="timedev-section-scene_cryptos">
                <p v-for="(element, index) in 6" 
                    :key="index"
                    class="timedev-section-scene_cryptos-p">{{$t(`development.time.crypto-item-${index}`)}}</p>
            </div>
        </div>

    </section>
</template>

<script>
export default {
    data: () => ({
        cryptos: []
    })
}
</script>

<style lang="sass" scoped>
@import '@/pages/development/sections/timedev.sass'
</style>