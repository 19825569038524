import { render, staticRenderFns } from "./developmentPage.vue?vue&type=template&id=e8512892&scoped=true&"
import script from "./developmentPage.vue?vue&type=script&lang=js&"
export * from "./developmentPage.vue?vue&type=script&lang=js&"
import style0 from "./developmentPage.vue?vue&type=style&index=0&id=e8512892&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8512892",
  null
  
)

export default component.exports