<template>
    <div class="floating-element">
        <div class="floating-element_description">
            <div class="floating-element_description-title">
                    <div class="floating-element_description-title_number"
                        v-if="!!numberMark">{{numberMark}}</div>
                    <div class="floating-element_description-title_text">{{$t(title)}}</div>
            </div>
            <div :class="specialClass">
                <div class="floating-element_description_item">
                    <div v-if="!!listFirst && !checkmarkOff"
                        class="floating-element_description_item-container"
                    >
                        <svg-icon name="checkmark-1"/>
                    </div>
                    <div class="floating-element_description_item_def" v-html="$t(listFirst)"></div>
                </div>
                <div class="floating-element_description_item">
                    <div v-if="!!listSecond"
                        class="floating-element_description_item-container"
                    >
                        <svg-icon name="checkmark-1"/>
                    </div>
                    <div class="floating-element_description_item_def" v-html="$t(listSecond)"></div>
                </div>
            </div>
        </div>
        <div class="floating-element_icon">
            <img class="floating-element_icon--img" :src="require(`@/assets/img/scenes/${icon}.svg`)" alt="">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        listFirst: {
            type: String,
            required: false
        },
        listSecond: {
            type: String,
            required: false
        },
        checkmarkOff: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        },
        numberMark: {
            type: Number,
            required: false
        },
        specialClass: {
            type: String,
            required: false,
            default() {
                return ''
            }
        },
    }
}
</script>

<style lang="sass" scoped>
@import '@/pages/apartials/floatingElement.sass'
</style>