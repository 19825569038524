import { render, staticRenderFns } from "./installation.vue?vue&type=template&id=c186abd8&scoped=true&"
var script = {}
import style0 from "./installation.vue?vue&type=style&index=0&id=c186abd8&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c186abd8",
  null
  
)

export default component.exports