<template>
    <section class="services-section">
        <div class="services-section-desc">
            <h3 class="services-section-desc__title">{{$t('development.services.title')}}</h3>
            <p class="services-section-desc__subtitle">{{$t('development.services.subtitle-1')}}</p>
            <img class="services-section-desc__rocket" :src="require(`@/assets/img/scenes/development-services-rocket.svg`)" alt="">
            <p v-html="$t('development.services.subtitle-2')" class="services-section-desc__subtitle"/>
            <p class="services-section-desc__subnote" >{{$t('development.services.subnote')}}</p>
        </div>
        <div class="services-section-icons">
            <div v-for="(el, i) in list"
                :key="i"
                class="services-section-icons_wrapper"
            >
                <div class="services-section-icons_item">
                    <img :src="require(`@/assets/img/services/${el.icon}.svg`)" alt="">
                </div>
                <p class="services-section-icons_subtext">{{$t(el.subtext)}}</p>
                <p class="services-section-icons_subnote">{{$t(el.subnote)}}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data: () => ({
        list: [
            {
                icon: 'services-bitcoin',
                subtext: 'development.services.subtext-0',
                subnote: 'development.services.subnote-0'
            },
            {
                icon: 'services-mobile-wallet',
                subtext: 'development.services.subtext-1',
                subnote: 'development.services.subnote-1'
            },
            {
                icon: 'services-telegram',
                subtext: 'development.services.subtext-2',
                subnote: 'development.services.subnote-2'
            },
            {
                icon: 'services-ethereum',
                subtext: 'development.services.subtext-3',
                subnote: 'development.services.subnote-3'
            }
        ]
    })
}
</script>

<style lang="sass" scoped>
@import '@/pages/development/sections/services.sass'
</style>